.custom-pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination-button {
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    border-radius: 24px;
    background-color: white;
    font-size: 16px;
    color: #666;
    cursor: pointer;
}

.pagination-button:disabled {
    color: #ccc;
    cursor: not-allowed;
}

.pagination-button.next {
    background-color: #4f46e5;
    color: white;
}

.pagination-button.previous {
    background-color: #ffffff;
    color: #4f46e5;
}

.page-numbers {
    display: flex;
    align-items: center;
    gap: 8px;
}

.pagination-number {
    padding: 8px 16px;
    border-radius: 50%;
    border: none;
    background-color: white;
    color: #666;
    cursor: pointer;
}

.pagination-number.active {
    background-color: #e0e0e0;
    color: #4f46e5;
}

.pagination-number:hover {
    background-color: #f0f0f0;
}